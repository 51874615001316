import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { CommonService } from "../services/common.service";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {
  appSettings: any;
  constructor(
    private spinner: NgxSpinnerService,
    private _commonService: CommonService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let publicAppId;
    let appId = environment.appId;

    if (req.url) {
      if (!req.url.includes("driver/v1")) {
        let token = "NA";
        let geofenceId = "NA";
        let timezone = "NA";
        let zoneId = "NA";
        if (localStorage.getItem("token")) {
          token = localStorage.getItem("token");
        }
        if (localStorage.getItem("appId")) {
          appId = localStorage.getItem("appId");
          publicAppId = appId;
        }
        if (localStorage.getItem("geofenceId")) {
          geofenceId = localStorage.getItem("geofenceId");
        }
        if (localStorage.getItem("geofence")) {
          zoneId = JSON.parse(localStorage.getItem("geofence"))["zoneId"];
        }
        if (localStorage.getItem("Timezone")) {
          timezone = localStorage.getItem("Timezone");
        }
        if (localStorage.getItem("publicAppId")) {
          publicAppId = localStorage.getItem("publicAppId");
        }
        let authReq;
        let refreshToken = localStorage.getItem("refreshToken");
        let headers = {
          Authorization: req.url.includes("/admin/refreshToken")
            ? refreshToken
            : token,
          geofenceid: geofenceId,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          appid: appId,
          zoneId: zoneId,
          date: moment().valueOf().toString(),
        };
        if (
          req.url.includes("upload/upload") ||
          req.url.includes("store/bulkUploadWallet") ||
          req.url.includes("upload/bulkUpload") ||
          req.url.includes("admin/bulkUploadUser") ||
          req.url.includes("admin/bulkUploadStore") ||
          req.url.includes("store/bulkUploadStoreType") ||
          req.url.includes("store/bulkUploadCategory") ||
          req.url.includes("store/bulkUploadSubCategory") ||
          req.url.includes("store/bulkUploadBrand") ||
          req.url.includes("store/bulkUploadProduct") ||
          req.url.includes("admin/bulkUploadProduct")
        ) {
          authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: req.url.includes("/admin/refreshToken")
                ? refreshToken
                : token,
              geofenceid: geofenceId,
              appid: appId,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              zoneId: zoneId,
            }),
          });
        } else {
          authReq = req.clone({
            headers: new HttpHeaders({
              hash: this._commonService.encryptData(JSON.stringify(headers)),
              deviceType: "web",
            }),
          });
        }

        const header1 = {
          Authorization: token,
          geofenceid: geofenceId,
          appid: publicAppId,
          date: moment().valueOf().toString(),
        };
        if (req.url.includes("/admin/getCrm")) {
          authReq = req.clone({
            headers: new HttpHeaders({
              hash: this._commonService.encryptData(JSON.stringify(header1)),
              deviceType: "web",
            }),
          });
        }

        if (req.method === "POST" || req.method === "PUT") {
          if (
            req.url.includes("upload/upload") ||
            req.url.includes("store/bulkUploadWallet") ||
            req.url.includes("upload/bulkUpload") ||
            req.url.includes("admin/bulkUploadUser") ||
            req.url.includes("admin/bulkUploadStore") ||
            req.url.includes("store/bulkUploadStoreType") ||
            req.url.includes("store/bulkUploadCategory") ||
            req.url.includes("store/bulkUploadSubCategory") ||
            req.url.includes("store/bulkUploadBrand") ||
            req.url.includes("store/bulkUploadProduct") ||
            req.url.includes("admin/bulkUploadProduct")
          ) {
            authReq = authReq;
          } else {
            authReq["body"] = {
              hash: this._commonService.encryptData(
                JSON.stringify(authReq.body)
              ),
            };
          }
        }

        return authReq;
      }
    }
    return req;
  }
}
