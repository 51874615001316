import { environment } from "src/environments/environment";
export class coreUrlList {
  comm = environment.commonUrl;
  onDemand = environment.ondemandUrl;
  // File Upload

  //CountryCode
  // countryCode: string = `/assets/json/countryCode.json`;

  //  Common API
  uploadFile: string = `${this.comm}/upload/upload`;
  bulkUploadFile: string = `${this.comm}/upload/bulkUpload`;
  appSetting: string = `${this.comm}/admin/appSetting`;
  getAppSetting: string = `${this.comm}/admin/getAppSetting`;
  greatSettings = `${this.comm}/admin/greatSetting`;
  webSetting: string = `${this.comm}/admin/webSetting`;
  getWebSetting: string = `${this.comm}/admin/getWebSetting`;
  getpostwebLayout: string = `${this.comm}/admin/webLayout`;
  getAdminProfile: string = `${this.comm}/admin/getAdminProfile`;
  dashboard: string = `${this.comm}/admin/dashboard`;
  user: string = `${this.comm}/admin/user`;
  promoCode: string = `${this.comm}/admin/promocode`;
  deletePromoCode: string = `${this.comm}/admin/deletePromoCode`;
  itemWiseSalesPerAreaReport: string = `${this.comm}/admin/itemWiseSalesPerAreaReport`;
  getAllBroadCast: string = `${this.comm}/admin/getAllBroadCast`;
  broadcastNotification: string = `${this.comm}/admin/broadcast`;
  getBroadCastById: string = `${this.comm}/admin/getBroadCastById`;
  subAdmin: string = `${this.comm}/admin/subAdmin`;
  getSubAdminById: string = `${this.comm}/admin/subAdminById`;
  addCustomerSupport: string = `${this.comm}/user/customerSupport`;
  customerSupport: string = `${this.comm}/admin/customerSupport`;
  customerSupportReason: string = `${this.comm}/admin/customerSupportReason`;
  addFaq: string = `${this.comm}/admin/addFaq`;
  editFaq: string = `${this.comm}/admin/editFaq`;
  getFaq: string = `${this.comm}/admin/getAllFaq`;
  rearrangingFaq: string = `${this.comm}/admin/changeArrangingOrderFaq`;
  addCrm: string = `${this.comm}/admin/addCrm`;
  getCrm: string = `${this.comm}/admin/getCrm`;
  getCustomerSetting: string = `${this.comm}/admin/getCustomerSetting`;
  getPaymentMethods: string = `${this.comm}/admin/getPaymentMethods`;
  getPaymentMode: string = `${this.comm}/admin/getPaymentModes`;
  getSmsMethods: string = `${this.comm}/admin/getSmsMethods`;
  getEmailMethods: string = `${this.comm}/admin/getEmailMethods`;
  getCallMaskingMethods: string = `${this.comm}/admin/getCallMaskingMethods`;
  getAllPlugins: string = `${this.comm}/admin/plugins`;
  managePaymentMethods: string = `${this.comm}/admin/managePaymentMethods`;
  manageSmsMethods: string = `${this.comm}/admin/manageSmsMethods`;
  managefeatureoff: string = `${this.comm}/admin/manageFeatureOff`;
  manageEmailMethods: string = `${this.comm}/admin/manageEmailMethods`;
  manageCallMaskingMethods: string = `${this.comm}/admin/manageCallMaskingMethods`;
  updateProfile: string = `${this.comm}/admin/updateProfile`;
  changePassword: string = `${this.comm}/admin/adminChangePass`;
  getStatusList: string = `${this.comm}/admin/getConstants`;
  login: string = `${this.comm}/admin/login`;
  logout: string = `${this.comm}/admin/logout`;
  forgotPasswordSendEmail1: string = `${this.comm}/admin/forgotPassword`;
  checkToken: string = `${this.comm}/admin/panel/checkToken`;
  resetPassword: string = `${this.comm}/admin/panel/resetpass`;
  checkForgotPassword: string = `${this.comm}/admin/checkForgotPassword`;
  resetpassword: string = `${this.comm}/admin/resetpassword`;
  storeResetPassword: string = `${this.comm}/store/setPassword`;
  getIp: string = `https://api.ipify.org?format=json`;
  getAllPlans: string = `${this.comm}/admin/getAllPlans?`;
  subscribe: string = `${this.comm}/admin/subscribe`;
  unsubscribe: string = `${this.comm}/admin/cancelSubscribe`;
  addUserMembershipPlan: string = `${this.comm}/admin/addUserMembershipPlan`;
  getAllUserMembershipPlan: string = `${this.comm}/admin/getAllUserMembershipPlan`;
  getUserMemberShipbyid: string = `${this.comm}/admin/getUserMembershipPlanById`;
  updateUserMembership: string = `${this.comm}/admin/updateUserMembershipPlan`;
  deleteMembershipPlan: string = `${this.comm}/admin/deleteUserMembershipPlan`;
  document: string = `${this.comm}/admin/document`;
  storeDocument: string = `${this.comm}/store/document`;
  rejectMerchant: string = `${this.onDemand}/admin/acceptRejectMerchant`;
  getUserById: string = `${this.comm}/admin/user`;
  userDocument: string = `${this.comm}/admin/userDocument`;
  getDocs: string = `${this.comm}/admin/document`;
  bulkUploadUser: string = `${this.comm}/admin/bulkUploadUser`;
  getAllUserExport: string = `${this.comm}/admin/getAllUserExport`;
  //Custome Template
  getDataType: string = `${this.comm}/admin/dataType`;
  customTemplates: string = `${this.comm}/admin/templateField`;
  getTemplateById: string = `${this.comm}/admin/templateFieldById`;
  getFormType: string = `${this.comm}/admin/getTemplateType`;
  // Payments
  getAllSalesPersonStores: string = `${this.comm}/admin/store/getAllSalesPersonStores`;
  getStoreTotalCommsion: string = `${this.comm}/admin/store/getAllMerchantTotalCommission`;
  getPaymentHistory: string = `${this.comm}/admin/payHistory`;
  payStore: string = `${this.comm}/admin/store/PAY`;
  orderWiseCommission: string = `${this.comm}/admin/store/getMerchantCommissionByOrder`;
  verifyRefreshToken: string = `${this.comm}/admin/refreshToken`;

  getUserAllOrders: string = `${this.onDemand}/admin/getAllOrdersCSV`;
  redeemUser: string = `${this.onDemand}/store/getredeemoptions`;
  getAllUsersCSV: string = `${this.onDemand}/admin/getAllUsersCSV`;
  getUserOrderCount: string = `${this.onDemand}/admin/getUserOrderCount`;

  userVehicleList: string = `${this.onDemand}/admin/getUserVehicle`;
  updateVehicle: string = `${this.onDemand}/admin/updateUserVehicle`;
  recentBooking: string = `${this.onDemand}/admin/recentBooking`;
  documents: string = `${this.onDemand}/admin/document`;

  // Zone
  getZones: string = `${this.onDemand}/zone/getZones`;

  //Geofence
  getGeofence: string = `${this.onDemand}/zone/geofence`;
  addGeofence: string = `${this.onDemand}//zone/addGeofence`;

  // Billing
  nextInvoice: string = `${this.onDemand}/admin/upcomingInvoices`;
  getAllInvoices: string = `${this.onDemand}/admin/getAllInvoices`;

  // PromoCodes (Deals and Coupans)
  changeArrangingOrderPromoCode: string = `${this.comm}/admin/changeArrangingOrderPromoCode`;

  // Taxi
  getRevenueTaxi: string = `${this.onDemand}/admin/revenue/taxi`;

  // Membership Plans
  addMembershipPlan: string = `${this.onDemand}/admin/addMembershipPlan`;

  getAllMembershipPlan: string = `${this.onDemand}/admin/getAllMembershipPlan`;
  getMembershipPlanById: string = `${this.onDemand}/admin/getMembershipPlanById`;
  editMembershipPlan: string = `${this.onDemand}/admin/editMembershipPlan`;

  // Vehical Types
  vehicalTypes: string = `${this.onDemand}/admin/vehicletype`;
  deleteVehicleType: string = `${this.onDemand}/admin/driver/deleteVehicleType`;
  driverVehicalTypes: string = `${this.onDemand}/driver/vehicletypes`;

  // Driver
  driver: string = `${this.onDemand}/admin/driver/detail`;
  addDriver: string = `${this.onDemand}/driver/register`;
  getDriverList: string = `${this.onDemand}/admin/driver/all`;
  getDriverOrderCount: string = `${this.onDemand}/admin/driver/getDriverOrderCount`;
  getDriverRatings: string = `${this.onDemand}/admin/driver/getDriversRatings`;
  updateDriverStatus: string = `${this.onDemand}/admin/driver/blockUnblockDriver`;
  driverDocument: string = `${this.onDemand}/admin/driver/document`;
  verifydocument: string = `${this.onDemand}/admin/driver/verifydocument`;
  driverByStatus: string = `${this.onDemand}/admin/driver/driverByStatus`;
  getAllVerticleTypes: string = `${this.onDemand}/driver/vehicletypes`;
  getAllDriverCSV: string = `${this.onDemand}/admin/getAllDriverCSV`;

  // Driver (using driver panel base url)
  getDriverToken: string = `${this.onDemand}/admin/driverToken`;

  // Admin Documents

  // Comapny
  addCompany: string = `${this.onDemand}/company/addCompany`;
  getCompany: string = `${this.onDemand}/company/getCompany`;
  getCompanyById: string = `${this.onDemand}/company/getCompanyById`;
  updateCompany: string = `${this.onDemand}/company/updateCompany`;
  getStoreInventoryAll: string = `${this.onDemand}/company/updateCompany`;

  // Customer Support

  // Gift Card
  addGift: string = `${this.onDemand}/admin/gift/addGift`;
  getAllGift: string = `${this.onDemand}/admin/gift/getAllGift`;
  updateGift: string = `${this.onDemand}/admin/gift/updateGift`;

  // Language Management --
  addLanguage: string = `${this.onDemand}/admin/addLanguage`;
  getAllLanguage: string = `${this.onDemand}/admin/getAllLanguage`;
  editLanguage: string = `${this.onDemand}/admin/editLanguage`;
  deleteLanguage: string = `${this.onDemand}/admin/deleteLanguage`;

  // Sales Report

  // Broadcast Notification

  // Sales Person
  addSalesPerson: string = `${this.onDemand}/admin/createSalesPerson`;
  deleteSalesPerson: string = `${this.onDemand}/admin/deleteSalesPerson`;
  getsalespersonbyid: string = `${this.onDemand}/admin/store/getsalespersonbyid`;
  updateSalesPerson: string = `${this.onDemand}/admin/store/editsalespersonbyId`;
  getSalesPersonList: string = `${this.onDemand}/admin/store/getAllSalesPerson`;

  // Admin Settings New Modules -- Start
  prefence: string = `${this.onDemand}/admin/prefence`;
  getAllReferal: string = `${this.onDemand}/admin/getAllReferal`;
  manageReferral: string = `${this.onDemand}/admin/manageReferral`;

  getSeo: string = `${this.onDemand}/admin/getSEO`;
  manageSeo: string = `${this.onDemand}/admin/manageSeo`;
  getCancellation: string = `${this.onDemand}/admin/getCancellation`;
  manageCancellation: string = `${this.onDemand}/admin/addCancellation`;
  deleteCancellation: string = `${this.onDemand}/admin/deleteCancellation`;
  manageCustomerSetting: string = `${this.onDemand}/admin/customerSettingMang`;
  manageNotification: string = `${this.onDemand}/admin/manageNotification`;
  getNotification: string = `${this.comm}/admin/getNotification`;
  countryJsonThirdParty: `https://openexchangerates.org/api/currencies.json`;
  subscribeToNotification: string = `${this.onDemand}/store/subscribe`;
  addEditGetTags: string = `${this.onDemand}/admin/tags`;
  getTagById: string = `${this.onDemand}/admin/tagsById`;
  tagSlot: string = `${this.onDemand}/admin/tagSlot`;
  createConnection: string = `${this.comm}/admin/createConnection`;
  getAddPutSlot: string = `${this.onDemand}/admin/deliverySlots`;
  deliverySlotsById: string = `${this.onDemand}/admin/deliverySlotsById`;
  addEditTax: string = `${this.comm}/admin/tax`;

  //ThirdParty
  // getTimeZone:`https://worldtimeapi.org/api/timezone`;

  // Assign Order
  AssignOrder: string = `${this.onDemand}/admin/freeDriverList`;
  assignDriverManually: string = `${this.onDemand}/admin/assignDriverManually`;

  // Warehouse
  warehouse: string = `${this.onDemand}/admin/warehouse`;
  getAllWarehouse: string = `${this.onDemand}/admin/getAllWarehouse`;
  assignOrderToWareHouse: string = `${this.onDemand}/admin/assignOrdersToWarehouse`;

  // Card releted Api
  addCard: string = `${this.onDemand}/admin/addCard`;
  getCard: string = `${this.onDemand}/admin/getCard`;
  deleteCard: string = `${this.onDemand}/admin/deleteCard`;

  // Address
  address: string = `${this.comm}/admin/address`;

  // Banner Notification
  bannerNotification: string = `${this.onDemand}/admin/bannerNotification`
}
