import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpService } from "../services/http.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  appBaseUrl = "https://devproduct.apptunix.com/";
  constructor(private router: Router, private http: HttpService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (window.location.href.includes("/auth/adminLogin")) {
      console.log(window.location.href);
      let isMulti = false;
      let url: any = window.location.href;
      url = url.split("?")[1].split("=");
      console.log(url);
      if (url[0] === "token") {
        localStorage.setItem("token", url[1].substr(0, url[1].length - 3));
        localStorage.setItem("appId", url[2]);
      }
      this.http
        .get(`${environment.commonUrl}/admin/greatSetting`)
        .subscribe((resp) => {
          if (resp["response"]["success"]) {
            isMulti = resp["data"]["appType"].length > 0 ? true : false;
            localStorage.setItem("greatSettings", JSON.stringify(resp["data"]));
          }
        });

      setTimeout(() => {
        if (localStorage.token && localStorage.appId) {
          this.router.navigate(isMulti ? ["/dashboard"] : ["/dashboards"]);
        }
      }, 1000);
    } else {
      return true;
    }

    if (state.url == "/auth/login") {
      if (
        localStorage.token === null ||
        localStorage.token === undefined ||
        localStorage.token == ""
      ) {
        return true;
      } else {
        this.router.navigate(["/dashboard"]);
        return false;
      }
    } else {
      if (
        localStorage.token == null ||
        localStorage.token == undefined ||
        localStorage.token == ""
      ) {
        console.log("3");
        this.router.navigate(["/auth/login"]);
      } else {
        return true;
      }
    }
  }
}
