export const environment = {
    production: false,
    credentials: {
      email: "",
      password: "",
    },
    baseUrl: "https://uat2-api-expo.appgrowthcompany.com",
    merchantUrl: "https://orders.quick-works.com/merchant",
    ondemandUrl: "/order/v3",
    commonUrl: "/common/v3",
    socketUrl: "https://uat2-order-expo.appgrowthcompany.com",
    appId: "9z4ny5gr921yq4lmk7qotqzl",
    errorHandlerArr: [],
  };
  