import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { appNoSpaceAtFirst } from "./directives/no-space.directive";
import { NumberDotOnlyDirective } from "./directives/number-dot-only.directive";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { TextOnlyDirective } from "./directives/only-text.directive";

import { TwoDigitDecimaNumberDirective } from "./directives/two-decimal-point.directive";
import { ReplaceTagDirective } from "./directives/replace-tag.directive";
import { TextWithSpaceDirective } from "./directives/text-with-space.diractive";
import { DecimalNumberDirective } from "./directives/decimal-number.directive";
import { RestrictCopyPaste } from "./directives/restrictCopyPaste.directive";
import { AverageDeliveryTimeDirective } from './directives/average-delivery-time.directive';

@NgModule({
  declarations: [
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
    NumberOnlyDirective,
    TextOnlyDirective,
    TwoDigitDecimaNumberDirective,
    ReplaceTagDirective,
    TextWithSpaceDirective,
    DecimalNumberDirective,
    RestrictCopyPaste,
    AverageDeliveryTimeDirective,
  ],
  exports: [
    appNoSpaceAtFirst,
    NumberDotOnlyDirective,
    NumberOnlyDirective,
    TextOnlyDirective,
    TwoDigitDecimaNumberDirective,
    TextWithSpaceDirective,
    DecimalNumberDirective,
    RestrictCopyPaste,
  ],
  imports: [CommonModule],
})
export class CoreModule {}
