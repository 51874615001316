import { Inject, Injectable } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { Title } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { coreUrlList } from "../urlList/core.urlList";
import { HttpService } from "./http.service";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  dropSetting = {
    singleSelection: false,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  keyForTaxi = [
    { displayeName: "taxi", moduleName: "taxi" },
    { displayeName: "shuttle", moduleName: "taxi" },
    { displayeName: "delivery", moduleName: "advanceDeliveryApp" },
  ];
  keyForOndemand = [
    { displayName: "Grocery", moduleName: "groceryApp" },
    { displayName: "Food", moduleName: "foodDeliveryApp" },
    { displayName: "E-commerce ", moduleName: "ecommerce" },
    { displayName: "E-commerce ", moduleName: "advanceecommerce" },
    { displayName: "Home Service", moduleName: "homeservice" },
  ];
  isLogOut = false;
  baseUrl;

  profileData$ = new Subject<string>();
  slotTime$ = new Subject<any>();
  appSettings$ = new Subject<string>();
  pendingOrderCount$ = new Subject<number>();
  isGeofenceAvailable$ = new Subject<string>();
  geofenceChnaged$ = new Subject<string>();
  active$ = new Subject<string>();
  isOrder$ = new Subject<boolean>();
  islogOut$ = new Subject<boolean>();
  isAppSettings$ = new Subject<any>();
  isSingleVendor$ = new Subject<string>();
  newOrder$ = new Subject<boolean>();

  // Socket Url
  socketUrl = environment.socketUrl;
  appBaseUrl = environment.baseUrl;
  emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  clientUrl: string;
  projectSettings: any;
  appSettings: any;
  currentPage: number;
  title: any;
  deliveryTitle: string;

  constructor(
    private toaster: ToastrManager,
    private http: HttpClient,
    private router: Router,
    private coreUrlList: coreUrlList,
    private api: HttpService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title
  ) {
    this.getCountryCode();
  }
  getClientBaseUrl() {
    this.clientUrl = "https://prod.webdevelopmentsolution.net:6029";
  }

  checkPermission(name) {
    var admin = JSON.parse(localStorage.getItem("subAdmin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.name == name && element.childs.length == 1) {
        data = {
          isView: element.childs[0].view,
          isEdit: element.childs[0].edit,
          isDelete: element.childs[0].delete,
        };
      }
    });
    return data;
  }

  getGreatSettings() {
    return this.http
      .get(this.appBaseUrl + `${environment.commonUrl}/admin/greatSetting`)
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.projectSettings = resp["data"];
          localStorage.setItem(
            "greatSettings",
            JSON.stringify(this.projectSettings)
          );
        }
      });
  }

  checkForAllKeys(newKey) {
    let greatSetting;
    if (localStorage.greatSettings) {
      greatSetting = JSON.parse(localStorage.getItem("greatSettings"));
    }
    for (const key in greatSetting) {
      if (greatSetting[newKey] === true) {
        return true;
      } else {
        if (greatSetting.appType.find((item) => item[newKey] === true)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  getforTaxi() {
    return this.keyForTaxi;
  }

  getAppSettings() {
    return this.http
      .get(this.appBaseUrl + `${environment.commonUrl}/admin/getAppSetting`)
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.appSettings = resp["data"];
          localStorage.setItem("appSettings", JSON.stringify(this.appSettings));

          this.setInitialSetup();
        }
      });
  }

  page() {
    return this.currentPage;
  }

  GreatSettings() {
    return this.http.get(
      this.appBaseUrl + `${environment.commonUrl}/admin/greatSetting`
    );
  }

  AppSettings() {
    return this.http.get(
      this.appBaseUrl + `${environment.commonUrl}/admin/getAppSetting`
    );
  }

  setInitialSetup() {
    // Set On localstorage for global access
    // localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color

    this.islogOut$.subscribe((res) => {
      this.isLogOut = res;
      //Setting default in case of logout
      if (res) {
        document.documentElement.style.setProperty(
          "--primary-color",
          "#6b676f"
        );
        // Set Favicon
        this._document
          .getElementById("appFavicon")
          .setAttribute("href", "forget");
        // Set Title
        this.titleService.setTitle("Admin Panel");
      }
    });
    if (!this.isLogOut) {
      if (this.appSettings && this.appSettings.colorCodes) {
        document.documentElement.style.setProperty(
          "--primary-color",
          this.appSettings.colorCodes
        );
      }

      // Set Favicon
      if (this.appSettings && this.appSettings.favIcon) {
        this._document
          .getElementById("appFavicon")
          .setAttribute("href", this.appSettings.favIcon);
      }
      // Set Title
      if (this.appSettings && this.appSettings.appName) {
        this.titleService.setTitle(this.appSettings.appName + " - Admin Panel");
      }
    }
  }

  getmoduleNameByKey(moduleKey) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));

    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        moduleName = element.moduleName;
      }
    });
    return moduleName;
  }

  setTitleByModuleName(moduleName, type) {
    switch (moduleName) {
      case "foodDeliveryApp": {
        this.title = "Cuisine " + type;
        if (type === "store") {
          this.title = "Restaurants";
        }
        if (type === "Product") {
          this.title = "Product";
        }
        break;
      }
      case "homeservice": {
        this.title = "Provider " + type;
        if (type === "store") {
          this.title = "Provider";
        }
        if (type === "Product") {
          this.title = "Service";
        }
        break;
      }
      case "pharmacy": {
        this.title = "Pharmacy " + type;
        if (type === "store") {
          this.title = "Pharmacy";
        }
        if (type === "Product") {
          this.title = "Medicine";
        }
        break;
      }
      default: {
        this.title = "Merchant " + type;
        if (type === "store") {
          this.title = "Merchant";
        }
        if (type === "Product") {
          this.title = "Product";
        }
        break;
      }
    }
    return this.title;
  }
  setTitleForDelivery(moduleName) {
    if (moduleName === "homeservice") {
      this.deliveryTitle = "Service";
    } else {
      this.deliveryTitle = "Delivery";
    }
    return this.deliveryTitle;
  }
  deleteEmptyKeys(value) {
    for (const key in value) {
      if (
        value[key] === "" ||
        value[key] === null ||
        value[key] === undefined
      ) {
        delete value[key];
      }
    }
    return value;
  }
  changeTimeInpToMin(startTime, endTime) {
    startTime =
      startTime !== ""
        ? Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1])
        : 0;
    endTime =
      endTime !== ""
        ? Number(endTime.split(":")[0]) * 60 + Number(endTime.split(":")[1])
        : 0;
    return { startTime: startTime, endTime: endTime };
  }

  convertMinToStr(startTime, endTime) {
    if (startTime > 0 || endTime > 0) {
      let newStartTime, newEndTime;
      newStartTime = String(Math.floor(startTime / 60)) + ":";
      newEndTime = String(Math.floor(endTime / 60)) + ":";
      if (startTime - Number(Math.floor(startTime / 60)) * 60 < 10) {
        startTime =
          newStartTime +
          `0${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      } else {
        startTime =
          newStartTime +
          `${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      }
      if (endTime - Number(Math.floor(endTime / 60)) * 60 < 10) {
        endTime =
          newEndTime + `0${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      } else {
        endTime =
          newEndTime + `${endTime - Number(Math.floor(endTime / 60)) * 60}`;
      }
    } else {
      if (startTime === 0) {
        startTime = "00:00";
      }
      if (endTime === 0) {
        endTime = "00:00";
      }
    }
    return { startTime: startTime, endTime: endTime };
  }

  getVerticalTypeByModuleKey(moduleKey) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        moduleName = element.moduleName;
      }
    });
    let verticalType;
    if (
      moduleName == "foodDeliveryApp" ||
      moduleName == "groceryApp" ||
      moduleName == "ecommerce" ||
      moduleName == "pharmacy" ||
      moduleName == "homeservice" ||
      moduleName == "advanceecommerce"
    ) {
      verticalType = 1;
    } else if (moduleName == "taxi" || moduleName == "deliveryApp") {
      verticalType = 2;
    } else if (moduleName == "shuttle") {
      verticalType = 3;
    } else if (moduleName == "renting") {
      verticalType = 4;
    }
    return verticalType;
  }

  checkPermissionwithchildren(moduleKey, name) {
    let displayName;
    let moduleName;
    var greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    greatSettings.appType.forEach((element) => {
      if (element.moduleKey == moduleKey) {
        (displayName = element.displayName), (moduleName = element.moduleName);
      }
    });
    var admin = JSON.parse(localStorage.getItem("subAdmin"));
    let data;
    admin.permissions.forEach((element) => {
      if (element.parent == true) {
        if (element.name == displayName) {
          if (moduleName == "foodDeliveryApp" && name == "Merchant Types") {
            name = "Cuisine Types";
          } else if (moduleName == "foodDeliveryApp" && name == "Categories") {
            name = "Categories";
          } else if (
            moduleName == "foodDeliveryApp" &&
            name == "Manage Merchants"
          ) {
            if (greatSettings.appType.vendorType == "single")
              name = "Manage Cuisine Products";
            else {
              name = "Restaurants";
            }
          }

          var childs = element.childs;
          childs.forEach((ele) => {
            if (ele.childName == name) {
              data = {
                isView: ele.view,
                isEdit: ele.edit,
                isDelete: ele.delete,
              };
            }
          });
        }
      }
    });
    return data;
  }
  iv = CryptoJS.enc.Base64.parse("D904363DB8DACEB8"); //giving empty initialization vector
  key = CryptoJS.SHA256("MKOIJNQASDFVRGHNBHUCFTXDRESZWA"); //hashing the key using SHA256
  encryptedString: any;
  encryptData(data) {
    if (typeof data == "string") {
      data = data.slice();
      this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    } else {
      this.encryptedString = CryptoJS.AES.encrypt(data, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
    }

    return this.encryptedString.toString();
  }
  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map((response) => response));
  }
  getCountryCurrency() {
    return this.http
      .get<Response>("assets/json/Common-Currency.json")
      .pipe(map((response) => response));
  }
  async uploadImage(file) {
    let formData = new FormData();
    formData.append("file", file);
    let res = await this.api
      .post(this.coreUrlList.uploadFile, formData)
      .toPromise();
    return res["data"];
  }
  async bulkUploadImage(file) {
    let data = new FormData();
    Object.keys(file).forEach((item, i) => {
      data.append("file", file[item]);
    });

    let res = await this.api
      .post(this.coreUrlList.bulkUploadFile, data)
      .toPromise();
    return res["data"];
  }

  successToast(message) {
    this.toaster.successToastr(message, "", {
      maxShown: 1,
      toastTimeout: 5000,
    });
  }
  errorToast(message) {
    this.toaster.errorToastr(message);
  }

  getFromLocalStorage(varName) {
    return JSON?.parse(localStorage.getItem(varName));
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.appSettings$.next("added");
  }

  removeItem(key) {
    localStorage.removeItem(key);
    this.appSettings$.next("removed");
  }

  confirmToast(text) {
    return Swal.fire({
      title: "Are you sure?",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  confirmToastWithTittle(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then();
  }

  confirmToastDynamicTitleTextIcon(title, text, icon) {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
  }

  successDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      timer: 1000,
      icon: "success",
      showConfirmButton: false,
    }).then();
  }
  errorDialog(title) {
    return Swal.fire({
      title: title,
      timer: 1500,
      icon: "error",
      showConfirmButton: false,
    }).then();
  }

  warningDialog(text, title) {
    return Swal.fire({
      title: title,
      text: text,
      timer: 1000,
      icon: "warning",
      showConfirmButton: false,
    }).then();
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appId", greatSettings["appId"]);
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    this.router.navigateByUrl("/auth/login");
  }
}
