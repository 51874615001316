import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PublicApiService {
  constructor() {}

  publicApi(script,key) {
    var head = document.getElementsByTagName('body')[0];

    script.type = "text/javascript";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${
      key ? key : ""
    }&libraries=places,drawing,geometry,visualization&language=en`;
    script.id = "testScriptName";
    script.async = true;
    head.appendChild(script);
    console.log("4");

  }
}
