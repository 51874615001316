import { Injectable, ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/browser";
import { environment } from "src/environments/environment";

@Injectable()
class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: "https://ce1d7ef5fcb63f6d43147d2ba5075c8c@o4506098137890816.ingest.sentry.io/4506098203885568",

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: "expo-admin@2.3.12",
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      //   tracePropagationTargets: [
      //     /^https:\/\/[a-z0-9-]+\.home\.quick-works\.com\//,
      //     /^https:\/\/[a-z0-9-]+\.demo\.quick-works\.com\//,
      //   ],

      enableTracing: true,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error);
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
