import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { interval, Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, finalize, tap } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { HttpService } from "../services/http.service";
import { coreUrlList } from "../urlList/core.urlList";

@Injectable({
  providedIn: "root",
})
export class GetInterceptorService {
  tokenTime: any;
  interval: number;
  refreshToken: string;
  constructor(
    private toaster: ToastrManager,
    private ngxService: NgxSpinnerService,
    private router: Router,
    private https: HttpService,
    private http: HttpClient,
    private coreUrlList: coreUrlList
  ) {
    this.refreshToken = localStorage.getItem("refreshToken");
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.show();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.hide();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));

          if (eventRes.body) {
            if (
              eventRes.body &&
              eventRes.body.response &&
              !eventRes.body.response.success
            ) {
              this.toaster.errorToastr(eventRes.body.response.message);
            }
          }
        },
        (error) => {
          if (error.status === 400) {
            if (error.url.includes("store/bulkUploadWallet")) {
              for (let item of error.error.data) {
                this.toaster.errorToastr(
                  `${item.error} at row ${item.row} column ${item.column + 1}`
                );
              }
            } else {
              this.toaster.errorToastr(error.error.response.message);
            }
          } else if (environment) {
            environment.errorHandlerArr.push(error.status);

            if (environment.errorHandlerArr.length === 1) {
              if (
                environment.errorHandlerArr[0] === 500 ||
                environment.errorHandlerArr[0] === 404 ||
                environment.errorHandlerArr[0] === 401 ||
                environment.errorHandlerArr[0] === 504
              ) {
                if (environment.errorHandlerArr[0] === 401) {
                  // this.logOut();
                  this.getRefreshToken();
                }
                this.toaster.errorToastr(
                  "Oops!! Something went wrong ! Please Try Again"
                );
              }
            }
            if (environment.errorHandlerArr.length > 1) {
              this.logOut();
            }
            if (environment.errorHandlerArr.length === 5) {
              environment.errorHandlerArr = [];
              this.getRefreshToken();
            }
          }
        }
      )
    );
  }

  getRefreshToken() {
    this.https
      .get(this.coreUrlList.verifyRefreshToken)
      .subscribe((res: any) => {
        if (res["response"]["success"]) {
          if (res["data"]["authToken"]) {
            localStorage.setItem("token", res["data"].authToken);
          }
          if (res["data"]["refreshToken"]) {
            localStorage.setItem("refreshToken", res["data"].refreshToken);
          }
        }
      });
  }

  getTimeaccType() {
    switch (this.tokenTime) {
      case this.tokenTime.includes("h"):
        this.interval = this.tokenTime.split("h")[0] * 60 * 60;
        this.getRefreshToken();
        break;
      case this.tokenTime.includes("m"):
        this.interval = this.tokenTime.split("m")[0] * 60;
        this.getRefreshToken();
        break;
      case this.tokenTime.includes("s"):
        this.interval = this.tokenTime.split("s")[0];
        this.getRefreshToken();
        break;
    }
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appId", greatSettings["appId"]);
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    this.router.navigateByUrl("/auth/login");
  }
}
