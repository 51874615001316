import { Injectable, Inject, HostBinding } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
import { PublicApiService } from "./public-api.service";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class InitialService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    private _publicApi: PublicApiService
  ) {}
  appBaseUrl = environment.baseUrl;
  api = this.appBaseUrl + +environment.commonUrl + "/admin/greatSetting";
  projectSettings: any;
  greatSettings: any;
  appSettings = new Subject();
  script = document.createElement("script");

  async load() {
    console.log("initial called");

    let fullUrl = window.location.href;

    this.api = fullUrl.includes("localhost")
      ? `${this.appBaseUrl}${environment.commonUrl}/admin/greatSetting`
      : `${this.appBaseUrl}${environment.commonUrl}/admin/greatSetting?isFromWeb=true`;

    await this.getGreatSettings();
    this.subscribeToAppSettings();
  }

  subscribeToAppSettings() {
    this.appSettings.subscribe((res) => {
      if (res) {
        this.projectSettings = res;
        this.setData();
      }
    });
  }

  async getGreatSettings() {
    let resp = await this.http.get(this.api).toPromise();
    if (resp["response"]["success"] && resp["data"].isActive) {
      if (!document.getElementById("testScriptName")) {
        this.setApikey();
      }
      this.greatSettings = resp["data"];

      // Only Use Modules which are active
      if (this.greatSettings?.appType) {
        this.greatSettings.appType = this.greatSettings.appType.filter(
          (appType) => appType.status == true
        );
        if (this.greatSettings.appType.length == 0) {
          alert("Please select a module from great admin");
        }
        // Set On localstorage for global access
        localStorage.setItem(
          "greatSettings",
          JSON.stringify(this.greatSettings)
        );
        localStorage.setItem("appId", this.greatSettings["appId"]);
        // Set project Base Url
        this.loadAppSpecificSettings();
      }
      return;
    } else if (!resp["data"].isActive) {
      console.log(2);

      alert(
        `Project disabled by Apptunix. For any help please contact us on https://appdukaan.com/.`
      );
    } else {
      alert(`Failure From Great Admin !- ${resp["message"]}`);
    }
  }

  async loadAppSpecificSettings() {
    this.http
      .get(this.appBaseUrl + environment.commonUrl + "/admin/getAppSetting")
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.projectSettings = resp["data"];

          this.setData();
        }
      });
  }

  setData() {
    // Set On localstorage for global access
    localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color
    if (this.projectSettings && this.projectSettings.colorCodes) {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.projectSettings.colorCodes
      );
    }
    // Set Google Api keY DYNAMICALLY
    var head = document.getElementsByTagName("body")[0];
    if (!document.getElementById("testScriptName")) {
      this.script.type = "text/javascript";
      this.script.src = `https://maps.googleapis.com/maps/api/js?key=${
        this.projectSettings?.["webappGoogleApiKey"]
          ? this.projectSettings?.["webappGoogleApiKey"]
          : ""
      }&libraries=places,drawing,geometry,visualization&language=en`;
      this.script.id = "newTestScriptName";
      this.script.async = true;
      head.appendChild(this.script);
    }

    this.noAGMScript();

    // Set Favicon
    if (this.projectSettings && this.projectSettings.favIcon) {
      this._document
        .getElementById("appFavicon")
        .setAttribute("href", this.projectSettings.favIcon);
    }
    // Set Title
    if (this.projectSettings && this.projectSettings.appName) {
      this.titleService.setTitle(
        this.projectSettings.appName + " - Admin Panel"
      );
    }

    // Set On localstorage for global access
    localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color
    if (this.projectSettings && this.projectSettings.colorCodes) {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.projectSettings.colorCodes
      );
    }
    // Set Favicon
    if (this.projectSettings && this.projectSettings.favIcon) {
      this._document
        .getElementById("appFavicon")
        .setAttribute("href", this.projectSettings.favIcon);
    }
    // Set Title
    if (this.projectSettings && this.projectSettings.appName) {
      this.titleService.setTitle(
        this.projectSettings.appName + " - Admin Panel"
      );
    }
  }

  setApikey() {
    if (!localStorage.appSettings) {
      this._publicApi.publicApi(this.script, "");
      this.noAGMScript();
    } else {
      let apiKey = JSON.parse(localStorage.getItem("appSettings"))[
        "webappGoogleApiKey"
      ];
      this._publicApi.publicApi(this.script, apiKey);
      this.noAGMScript();
    }
  }
  noAGMScript() {
    if (document.getElementById("agmGoogleMapsApiScript")) {
      let NoScript = document.getElementById("agmGoogleMapsApiScript");
      NoScript.remove();
    }
  }

  public getBaseUrl() {
    if (this.greatSettings) {
      const baseUrl = this.appBaseUrl;

      return {
        baseUrl: baseUrl,
        imageUrl: this.appBaseUrl,
      };
    }
  }
}
