import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  greatSettings: any;
  googleApi: any;
  constructor(
    public myRoute: Router,
    public updates: SwUpdate,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    // navigator.serviceWorker.register("/ngsw-worker.js");
  }

  ngOnInit() {
    //   this.updates.versionUpdates.subscribe((evt) => {
    //     switch (evt.type) {
    //       case 'VERSION_DETECTED':
    //         console.log(`Downloading new app version: ${evt.version.hash}`);
    //         break;
    //       case 'VERSION_READY':
    //         console.log(`Current app version: ${evt.currentVersion.hash}`);
    //         console.log(
    //           `New app version ready for use: ${evt.latestVersion.hash}`
    //         );
    //         alert("Please Hard Reload Panel (Ctrl + Shift + R)")
    //         break;
    //       case 'VERSION_INSTALLATION_FAILED':
    //         console.log(
    //           `Failed to install app version '${evt.version.hash}': ${evt.error}`
    //         );
    //         break;
    //     }
    //   });
    //   this.updates.unrecoverable.subscribe((event) => {
    //     console.log('unrecoverable state', event.reason);
    //     if (
    //       window.confirm('An error occurred that we cannot recover from, please click OK to reload the page.') ===
    //       true
    //     ) {
    //       window.location.reload();
    //     }
    //   });
  }
}
